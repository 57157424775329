import React from 'react'
import Bussines from '../components/bussines'
function businesspage() {
  return (
    <div>
      <Bussines/>
    </div>
  )
}

export default businesspage
