import React from 'react'
import Careersection from '../components/careersection'

const Career = () => {
  return (
   <>
   <Careersection />
   </>
  )
}

export default Career
