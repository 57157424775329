import React from 'react'
import From from '../components/From'

function Contact() {
  return (
    <>
    <From />
    </>
  )
}

export default Contact
