import React from 'react'
import Mission from '../components/mission';
import Meetourteam from '../components/meetourteam';


function Company() {
  return (
    <>
      <Mission />
      <Meetourteam />
    </>
  )
}

export default Company
