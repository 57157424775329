import React from 'react'
// import PortfolioSection from '../Components/portfoliosection';
import Portfoliopage from '../components/portfoliopage';

function Portfolio() {
  return (
    <>
      {/* <PortfolioSection /> */}
      <Portfoliopage />
    </>
  )
}

export default Portfolio
