import React from 'react'
import Blog from '../components/blog'

const blogpage = () => {
  return (
    <>
    <Blog />
    </>
  )
}

export default blogpage
